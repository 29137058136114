<template>
  <Section>
    <Container>
      <template v-for="(notation, index) in notations"
        :key="index">
        <template v-if="notation?.variables_etudiees && notation.variables_etudiees.length > 0">
          <h3>
            {{ notation.designation ?? notation.type.designation }}

            <div><em class="font-size-small color-gray-light">{{ notation.date_realisation
              ? `Réalisé le ${helperService.displayDate(notation.date_realisation)}`
              : null
            }}</em></div>
          </h3>
          <template v-for="(studiedVariable, j) in notation.variables_etudiees"
            :key="j">
            <div class="form grid">
              <div class="tmp-col">
                <Checkbox :id="studiedVariable.id"
                  :modelValue="form.variables.findIndex((m) => m.id === studiedVariable.id) !== -1"
                  @update:modelValue="selectVariables(studiedVariable, 've')"
                  :items="[{
                      label:
                        `${studiedVariable.designation}`,
                      value: true,
                    }]" />
              </div>
              <div class="tmp-col padding"><templte v-if="studiedVariable.saisie_niveau.organe">Organe: {{studiedVariable.saisie_niveau.organe?.valeur}}</templte></div>
              <div class="tmp-col padding"><templte v-if="studiedVariable.cible">Cible: {{studiedVariable.cible.valeur}}</templte></div>
              <div class="tmp-col"></div>
            </div>
          </template>
          <template v-for="(calculatedVariable, k) in notation.variables_calculees"
            :key="k">
            <Checkbox :id="calculatedVariable.id"
              :modelValue="form.variables.findIndex((m) => m.id === calculatedVariable.id) !== -1"
              @update:modelValue="selectVariables(calculatedVariable, 'vc')"
              :items="[{
                  label:
                    `${calculatedVariable.calcul.designation}`,
                  value: true,
                }]" />
          </template>
        </template>
      </template>
    </Container>
  </Section>
</template>

<script>
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import Checkbox from '@/components/form/Checkbox.vue'

export default {
  name: 'VariablesAddSubview',
  components: {
    Checkbox,
    Container,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    // Define a validation schema
    const validationSchema = this.yup.object().shape({})

    // init du form
    const { errors, isSubmitting } = this.formService.initFrom(validationSchema)

    // gestion du onSubmit
    const onSubmit = this.formService.handleSubmit(() => {
      this.handleValues()
    })

    return {
      onSubmit,
      errors,
      isSubmitting,
      method: 'post',
      endpoint: null,
      essai: {},
      notations: [],
      studiedVariables: [],
      calculatedVariables: [],
      variables: [],
      fiches: [],
      loading: false,
      form: {
        variables: [],
      },
    }
  },
  emits: ['set-step', 'set-data', 'set-route', 'updateEssais', 'update:modelValue'],
  watch: {
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
    modelValue() {
      if (this.modelValue) {
        this.onSubmit()
      }
      this.$emit('update:modelValue', false)
    },
  },
  mounted() {
    if (!this.$route.name.includes('Edit')) {
      this.previousRouteName = 'essaiChildFixedReportRepetitionsAdd'
    }

    this.$emit('set-route', {
      previousRouteName: this.previousRouteName,
      routeParams: {
        id: this.$route.params.id,
        rid: this.$route.params.rid,
      },
    })

    this.$emit('set-step', 3)

    this.getVariables()
    this.getSelectedStudiedVariables()
    this.getSelectedCalculatedVariables()
  },
  methods: {
    /**
     * Get variables from events essai
     */
    getVariables() {
      this.emitter.emit('open-loader')
      this.loading = true
      this.fetchService.get(`essai/${this.$route.params.id}/evenement`, { 'type.uid': 'NOTATION' }).then((event) => {
        this.notations = event.data.filter((e) => e.date_realisation !== null)

        Promise.all(
          this.notations.map((notation) => {
            this.fetchService
              .get(`essai/${this.$route.params.id}/evenement/${notation.id}/fiche/notation`)
              .then((fiches) => {
                fiches.data.forEach((fiche) => {
                  this.fetchService
                    .get(`essai/${this.$route.params.id}/evenement/${notation.id}/fiche/notation/${fiche.id}/variable_etudiee`)
                    .then((veData) => {
                      // eslint-disable-next-line
                      notation.variables_etudiees = veData.data.filter((d) => {
                        return d.id !== null
                      })

                      notation.variables_etudiees.map(async (element) => {
                        if (element.saisie_niveau && element.saisie_niveau.uid === 'ORGANE') {
                          const endpoint = `essai/${this.$route.params.id}/evenement/${notation.id}/fiche/notation/${fiche.id}/variable_etudiee`
                          const niveau = await this.fetchService.get(`${endpoint}/${element.id}/niveau_saisie`)
                          if (niveau.data.length > 0) {
                            const organe = niveau.data.pop()
                            // eslint-disable-next-line no-param-reassign
                            element.saisie_niveau.organe = organe.organe
                          } else {
                            // eslint-disable-next-line no-param-reassign
                            element.saisie_niveau.organe = null
                          }
                        }
                      })
                    })
                  this.fetchService
                    .get(`essai/${this.$route.params.id}/evenement/${notation.id}/fiche/notation/${fiche.id}/variable_calculee`)
                    .then((vcData) => {
                      // eslint-disable-next-line
                      notation.variables_calculees = vcData.data.filter((d) => {
                        return d.id !== null
                      })
                    })
                })
              })
            return notation
          }),
        ).then(() => {
          this.loading = false
          this.emitter.emit('close-loader')
        })
      })

      // this.getSelectedStudiedVariables()
      // this.getSelectedCalculatedVariables()
    },

    /**
     * Get already selected studied variables
     */
    getSelectedStudiedVariables() {
      this.fetchService
        .get(`essai/${this.$route.params.id}/rapport/${this.$route.params.rid}/variable`)
        .then((response) => {
          response.data.forEach((studiedVariable) => {
            if (studiedVariable.id !== null) {
              this.selectVariables(studiedVariable, 've')
            }
          })
        })
    },

    /**
     * Get already selected calculated variables
     */
    getSelectedCalculatedVariables() {
      this.fetchService
        .get(`essai/${this.$route.params.id}/rapport/${this.$route.params.rid}/variable_calculee`)
        .then((response) => {
          response.data.forEach((calculatedVariable) => {
            if (calculatedVariable.id !== null) {
              this.selectVariables(calculatedVariable, 'vc')
            }
          })
        })
    },

    selectVariables(variable, type) {
      const index = this.form.variables.findIndex((m) => m.id === variable.id)
      if (index > -1) {
        this.form.variables.splice(index, 1)
      } else {
        this.form.variables.push({
          id: variable.id,
          type,
        })
      }
    },

    handleValues() {
      this.emitter.emit('open-loader')
      const ve = this.form.variables.filter((variable) => variable.type === 've').map((v) => {
        // eslint-disable-next-line
        delete v.type
        return v
      })
      const vc = this.form.variables.filter((variable) => variable.type === 'vc').map((v) => {
        // eslint-disable-next-line
        delete v.type
        return v
      })

      Promise.all([
        this.fetchService
          .put(`essai/${this.$route.params.id}/rapport/${this.$route.params.rid}/variable`,
            {
              variables: ve,
            }),
        this.fetchService
          .put(`essai/${this.$route.params.id}/rapport/${this.$route.params.rid}/variable_calculee`,
            {
              variables_calculees: vc,
            }),
      ]).then(() => {
        this.fetchService
          .patch(`essai/${this.$route.params.id}/rapport/${this.$route.params.rid}/statistiques`)
          .then(() => {
            this.emitter.emit('alert', {
              type: 'success',
              content: 'Le rapport fixe a bien été crée.',
            })

            this.$router.push({
              name: 'essaiChildFixedReports',
              params: {
                id: this.$route.params.id,
              },
            })

            this.emitter.emit('close-loader')
          })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.padding{
  padding: 1rem 0;
}
</style>
